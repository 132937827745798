import {
  Box, Breadcrumbs,
  // Button,
  Grid, makeStyles, Theme, Typography
} from '@material-ui/core'
// import TwitterIcon from '@material-ui/icons/Twitter'
import { BigNumber } from 'bignumber.js'
import { bnOrZero, BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import { CellLink, DataLoadSegment, NotFoundState, Page, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { Paths, TaskNames } from 'js/constants'
import { getTokenPrice } from 'js/helpers'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, useEffect, useState } from 'react'
import { OrderDetails, OrderUserDetails, RelatedTrades } from './components'
import { TradeEvent } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/event'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { QueryTradesRequest } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/query'

interface Props { }

export enum CancelReason {
  '(0) Unknown' = 0,
  '(1) Failed validation' = 1,
  '(2) Cannot fulfill post only' = 2,
  '(3) Cannot fulfil reduce only' = 3,
  '(4) Insufficient margin' = 4,
  '(5) Execution price outside trading bandwidth' = 5,
  '(6) Market closed' = 6,
  '(7) Made position pool negative' = 7,
  '(8) Order execution price too poor' = 8,
  '(9) Liquidation engine requote' = 9,
  '(10) TIF expired' = 10,
  '(11) Position being liquidated' = 11,
  '(12) Derisk' = 12,
  '(13) Pool in route already used' = 13,
  '(14) No mark price' = 14,
  '(15) Max leverage exceeded' = 15,
  '(16) Matched own order' = 16,
  '(17) Reason AMM updated' = 17,
  '(18) User initiated' = 18,
  '(19) Kill Virtual Order' = 19,
  '(20) Max Open Interest Exceeded ' = 20
}

interface Fees {
  [key: string]: BigNumber
}
interface CollectiveTrades {
  avgPrice: BigNumber,
  fees: Fees,
}

const Order: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  // TODO: Add TaskNames.App.GeckoCoin, TaskNames.Account.Profile, TaskNames.App.Markets
  const [loading] = useTaskSubscriber(TaskNames.Order.Detail)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const price = useRedux((state) => state.app.geckoPrices)
  const swthPrice = price?.switcheo
  //APIs
  const { order } = useRedux((state) => state.order)
  const { markets } = useRedux((state) => state.app)
  const [getTrades, tradeLoading] = useAsyncTask('getTrades')
  const [getProfile, profileLoading] = useAsyncTask('getProfile')
  const [getMarketStats, marketsLoading] = useAsyncTask('getMarketStats')
  const [getTokenPricing] = useAsyncTask('getTokenPricing')
  //States
  const [currentPrice, setCurrentPrice] = useState<number>(0)
  const [dollarValue, setDollarValue] = useState<number>(0)
  const [trades, setTrades] = useState<TradeEvent[]>([])
  const [username, setUsername] = useState<string>("")
  const [twitter, setTwitter] = useState<string>("")
  const [demexPrice, setDemexPrice] = useState<string>("")
  const [pairNotFound, setPairNotFound] = useState<Boolean>(false)
  const [takerOrMaker, setTakerOrMaker] = useState<string>("taker")
  const [marketDetails, setMarketDetails] = useState<Market | undefined>()
  const [collectiveTrades, setCollectiveTrades] = useState<CollectiveTrades>({ avgPrice: new BigNumber(0), fees: {} })
  // const [totalDollarValue, setTotalDollarValue] = useState<number>(0)
  const [isFutures, setIsFutures] = useState<boolean>(false)
  //For pagination
  const [page, SetPage] = useState<number>(1)
  const itemsPerPage = 5
  // const shareMsg = totalDollarValue >= 20000 ? `🚨 🐋 🚨 🐋  WHALE ALERT!! Check out this position on %23Demex!` : `Check out this position on %23Demex!`

  const avgTextDisplay = order?.isLiquidation && order.address !== "swth12uxz5eayngcls6lx55j9g28npen6z5fr7rkjww" ? 'Bankruptcy Price' : 'Avg Execution Price'

  useEffect(() => {
    getTrades(async () => {
      if (!order || !sdk) return
      try {
        const brokerQueryClient = sdk.query.broker
        const result = await brokerQueryClient.Trades(
          QueryTradesRequest.fromPartial({
            marketId: order.marketId,
            orderId: order.id,
          })
        )
        const filtered = result.trades.filter((trade: TradeEvent) => trade.takerId === order.id || trade.makerId === order.id)
        if (filtered && filtered.length > 0) {
          if (filtered[0].takerId === order.id) setTakerOrMaker("taker")
          else setTakerOrMaker("maker")
        }
        setTrades(filtered)
      } catch (err) {
        console.error(err)
      }
    })

    getProfile(async () => {
      if (!sdk || !order) return
      try {
        const profileQueryClient = sdk.query.profile
        const result = await profileQueryClient.Profile({ address: order?.address ?? '' })
        const profile = result.profile
        setUsername(profile?.username ?? '')
        setTwitter(profile?.twitter ?? '')
      } catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, sdk])

  //get current price
  useEffect(() => {
    if (!order || loading) return
    // TODO: Check implementation
    // if (!MARKETS_PAIR.includes(order.market) && markets[order.market].marketType === "spot") {
    //   setPairNotFound(true)
    //   return
    // }
    setPairNotFound(false)
    //Set tick_size, marketType ..etc
    setMarketDetails(markets[order.marketId])
    getTokenPricing(async () => {
      //Get token price in USD
      const marketDetails = markets[order.marketId]
      const baseDenom = marketDetails?.base ?? ''
      const quoteDenom = marketDetails?.quote ?? ''
      const geckoMap = sdk?.token?.geckoTokenNames ?? {}
      const baseDenomName = sdk?.token?.getTokenName(baseDenom) ?? ''
      const quoteDenomName = sdk?.token?.getTokenName(quoteDenom) ?? ''
      const baseDenomAsGeckoID = geckoMap[baseDenomName?.toLowerCase()]
      const quoteDenomAsGeckoID = geckoMap[quoteDenomName?.toLowerCase()]
      const baseDenomUSD = sdk ? await getTokenPrice(baseDenom.toLowerCase(), sdk) : BN_ZERO
      const quoteDenomUSD = sdk ? await getTokenPrice(quoteDenom.toLowerCase(), sdk) : BN_ZERO

      let currentPrice = quoteDenomUSD.isZero() ? BN_ZERO : baseDenomUSD.div(quoteDenomUSD)
      if (markets[order.marketId]?.marketType === "futures") setIsFutures(true)
      if (quoteDenomUSD.isZero() || baseDenomUSD.isZero()) {
        currentPrice = bnOrZero(price[baseDenomAsGeckoID]?.usd).div(quoteDenomUSD ?? price[quoteDenomAsGeckoID.toLowerCase()]?.usd)
      }
      setCurrentPrice(currentPrice.toNumber())
      setDollarValue(quoteDenomUSD?.isGreaterThan(0) ? quoteDenomUSD.toNumber() : price[quoteDenomAsGeckoID]?.usd)
    })

    //API for getting demex price
    getMarketStats(async () => {
      if (!sdk) return;
      try {
        // TODO: Migrate carbon
        // const result = await sdk.api.getMarketStats({ market: order.market })
        // setDemexPrice(result[0].last_price)
        setDemexPrice('')
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, sdk, loading, swthPrice])

  useEffect(() => {
    if (!loading && trades.length > 0 && order) {
      setCollectiveTrades(collectiveTrade => {
        let orderId = order.id
        let totalQuantity = new BigNumber(0)
        let avgPrice = new BigNumber(0);
        let fees: any = {};
        for (let i = 0; i < trades.length; i++) {
          totalQuantity = totalQuantity.plus(trades[i].quantity)
          avgPrice = avgPrice.plus(Number(trades[i].price) * Number(trades[i].quantity))
          const isTaker = orderId === trades[i].takerId
          let denom = trades[i][isTaker ? "takerFeeDenom" : "makerFeeDenom"]
          let tradeFees = Number(trades[i][isTaker ? "takerFeeAmount" : "makerFeeAmount"])
          if (!fees[denom]) fees[denom] = new BigNumber(0)
          fees[denom] = fees[denom].plus(tradeFees)
        }
        collectiveTrade.avgPrice = avgPrice.div(totalQuantity)
        collectiveTrade.fees = fees
        // collectiveTrade.denom = 
        return collectiveTrade
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerOrMaker, trades.length, order])

  // useEffect(() => {
  //   if (order && dollarValue > 0) {
  //     const totalValue = Number(order.quantity) * Number(order.price) * dollarValue
  //     setTotalDollarValue(totalValue)
  //   }
  // }, [dollarValue, order])

  //If trading pair is not found , return NotFoundState
  if (!loading && pairNotFound) return (
    <Page>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={`/markets`}>Markets</CellLink>
        <CellLink to={`/market/${encodeURIComponent(order?.marketId ?? '')}`}>{order?.marketId}</CellLink>
        <Typography>Order Details</Typography>
      </Breadcrumbs>
      <NotFoundState title="Trading pair not found">
        <Typography variant="body1">
          We can’t find any order with this ID. Please check your network setting or go back to the&nbsp;
          <CellLink to={Paths.Markets}>
            Orders
          </CellLink>
          &nbsp;page to view existing orders.
        </Typography>
      </NotFoundState>
    </Page>
  )

  return (
    <Page>
      <Breadcrumbs className={classes.breadcrumb}>
        <CellLink to={`/markets`}>Markets</CellLink>
        <CellLink to={`/market/${encodeURIComponent(order?.marketId ?? '')}`}>
          <span>
            {order?.marketId}
          </span>
        </CellLink>
        <Typography>Order Details</Typography>
      </Breadcrumbs>
      <DataLoadSegment loading={loading || tradeLoading || profileLoading || marketsLoading}>
        <Box display="flex" className={classes.box}>
          <Typography display={"inline"} className={classes.title} variant="h1">Order Details</Typography>
          {/* <Button
            color="secondary"
            className={classes.button}
            startIcon={<TwitterIcon fontSize={'small'} className={classes.twitterIcon} />}
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?text=${shareMsg}&url=https://scan.carbon.network/order/${order?.id}`,
                '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600',
              )
            }}
          >
            Share
          </Button> */}
        </Box>
        {order?.id && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={12} md={4}>
              <OrderUserDetails
                address={order.address}
                orderId={order.id}
                username={username}
                orderStatus={order.isLiquidation ? "Liquidated" : order.status}
                orderCancelReason={order.cancelReason}
                initiator={order.initiator}
                blockCreatedAt={order.blockCreatedAt?.toString() ?? ''}
                blockHeight={order.blockHeight.toNumber()}
                twitterHandler={twitter || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              {!!marketDetails && (
                <OrderDetails
                  marketDetails={marketDetails}
                  orderStatus={order.status}
                  market={order.marketId}
                  side={order.side}
                  orderType={order.orderType}
                  quantity={order.quantity}
                  price={order.price}
                  currentPrice={currentPrice}
                  dollarValue={dollarValue}
                  filled={order.filled}
                  timeInForce={order.timeInForce}
                  allocatedMarginDenom={order.allocatedMargin?.denom ?? ''}
                  stopPrice={order.stopPrice}
                  demexPrice={demexPrice}
                  collectiveTrades={collectiveTrades}
                  venue={"Demex"}
                  isFutures={isFutures}
                  referralAddress={order.referralAddress}
                  referralCommission={bnOrZero(order.referralCommission).minus(bnOrZero(order.referralKickback)).toNumber()}
                  referralKickback={order.referralKickback}
                  avgTextDisplay={avgTextDisplay}
                  triggerType={order.triggerType}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Section className={classes.section}>
          <div className={classes.titleContainer}>
            <Typography variant={"h2"} display={"inline"} className={classes.title}> Related Trades </Typography>
            <Typography display={"inline"}> Include all the trades that filled this order</Typography>
          </div>
          {(trades?.length > 0) && (
            <RelatedTrades
              trades={trades.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)}
              type={order?.orderType}
              takerOrMaker={takerOrMaker}
              address={order?.address}
            />
          )}
          {(!trades?.length) && (<TableEmptyState itemName={"trades"} />)}
          {trades && trades.length > 0 && (
            <Box paddingTop={3}>
              <PaginationByData
                data={trades}
                setPage={SetPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Box>
          )}
        </Section>
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  title: {
    marginRight: theme.spacing(0.5)
  },
  titleContainer: {
    marginBottom: theme.spacing(1)
  },
  twitterIcon: {
    margin: theme.spacing(0, -0.5, 0, 0.5),
  },
  button: {
    borderRadius: 0,
    marginLeft: 'auto',
    backgroundColor: switcheo.twitter,
    padding: theme.spacing(1.5, 3),
    alignSelf: 'center',
    height: 'fit-content',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: 'white',
    wordBreak: 'normal',
    justifyContent: 'center',
    "&:hover": {
      backgroundColor: switcheo.twitterHover
    }
  },
  box: {
    marginBottom: theme.spacing(1.5)
  },
  section: {
    margin: theme.spacing(5, 0)
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0),
    color: theme.palette.text.primary,
  },
}))

// Construct canvas banner and return its dataURL
// const OrderBanner = ( username: string, market: string, orderType: string, lots: string, denom: string, width: number = 600, height: number = 314) => {
//   const textColor = "#E2FCA4";
//   const background = "#073C50";
//   const sellTextColor = "#F24F4C";
//   const buyTextColor = "#38DD2A";
//   // const [base64, setBase64] = useState("");

//   const canvas = document.createElement("canvas");
//   canvas.width = width;
//   canvas.height = height;
//   const context = canvas.getContext("2d");
//   const innerWidth = canvas.width - 20;
//   const innerHeight = canvas.height - 20;
//   context!.fillStyle = "#FFFFFF";
//   context!.fillRect(10, 10, canvas.width, canvas.height);

//   context!.fillStyle = background;
//   context!.fillRect(10, 10, innerWidth, innerHeight);

//   context!.font = "30px Union Regular";
//   context!.fillStyle = textColor;
//   context!.fillText("TRADESCAN", 30, 50);

//   context!.font = "18px Union Regular";
//   context!.fillText(`${username} placed an order on Demex`, 30, 110);

//   context!.fillRect(30, 130, 300, 1);

//   context!.font = "18px Union Regular";
//   context!.fillText(`${market}`, 30, 160);
//   let textWidth = context!.measureText(market);
//   context!.fillText(`|`, 30 + textWidth.width + 10, 160);
//   context!.fillStyle = buyTextColor;
//   context!.font = "bold 18px Union Regular";
//   context!.fillText(`${orderType}`, 30 + textWidth.width + 25, 160);

//   context!.fillStyle = textColor;
//   context!.font = "bold 60px Union Regular";
//   context!.fillText(`${lots} ${denom}`, 30, 220);

//   context!.font = "18px Union Regular";
//   context!.fillText(`Price:`, 30, 260);
//   context!.fillText(`Created at:`, 30, 280);
//   textWidth = context!.measureText("Created at:");
//   context!.fillText(`0.0485 USDC`, 30 + textWidth.width + 30, 260);
//   context!.fillText(`2021-03-08 14:41:34`, 30 + textWidth.width + 30, 280);

//   return canvas.toDataURL("image/png", 1);
// };

export default Order
